<template>
  <div class="w-100 transition">
    <GenericReport
      title="Relatório de Aborto de Processos"
      :route="route"
      :topFilters="topFilters"
      :sideFilters="sideFilters"
      :fields="fields"
      tipoRelatorio="relatorioAbortoProcessos"
    />
  </div>
</template>

<script>
import moment from 'moment';

import GenericReport from '@/components/Table/GenericReport';

export default {
  name: 'relatorioAbortoDeProcessos',
  components: {
    GenericReport,
  },
  data() {
    return {
      route: 'report/abortoDeProcessos',
      descricao: '',
      topFilters: {
        id_unidade: {
          type: 'genericSelect',
          route: 'unidade',
          firstOption: {
            value: null,
            label: 'Unidade',
          },
        },
        id_sub_setor: {
          type: 'genericSelect',
          route: 'subSetor',
          firstOption: {
            value: null,
            label: 'Área',
          },
        },
        date: {
          type: 'rangeDate',
          defaultInitDate: moment().subtract(7, 'days'),
          defaultEndDate: moment(),
        },
      },
      sideFilters: {
        id: {
          type: 'input',
          label: 'ID',
        },
        descricao: {
          type: 'input',
          label: 'Descrição',
        },
        id_tipo_material: {
          type: 'genericSelect',
          label: 'Tipo de Material',
          route: 'tipoMaterial',
        },
        descricao_generico: {
          type: 'input',
          label: 'Descrição do Material Genérico',
        },
        id_tipo_material_generico: {
          type: 'genericSelect',
          label: 'Tipo de Material Genérico',
          route: 'tipoMaterialGenerico',
        },
      },
      fields: [
        {
          label: 'Tipo de material',
          key: 'tipoMaterial',
          formatter: (value) => value && value.nome,
        },
        {
          label: 'ID',
          key: 'shownId',
        },
        {
          label: 'Descrição',
          key: 'descricao',
        },
        {
          label: 'Cor',
          cor: 'cor',
        },
        {
          label: 'Qtd',
          key: 'qt_material_generico',
        },
        {
          label: 'Data/Hora',
          key: 'deletedAt',
          formatter: (value) => moment(value).format('DD/MM/YY HH:mm'),
        },
        {
          label: 'Usuário',
          key: 'usuario',
          formatter: (value) => value && value.nome,
        },
        {
          label: 'Protocolo',
          key: 'protocolo',
        },
        {
          label: 'Área',
          key: 'subSetor',
          formatter: (value) => value && value.nome,
        },
        {
          label: 'Processo',
          key: 'tipoProcesso',
          formatter: (value) => value && value.nome,
        },
        {
          label: 'Justificativa',
          key: 'observacaoAbortoProcesso',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
$primary-color-50: #209f85;

.paginador {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transition {
  transition: 0.3s ease-in-out;
}

#report h2 {
  margin-bottom: 30px;
}

#report table {
  background-color: #fff;
}

#report table thead th {
  color: #7e829f;
  padding-bottom: 50px;
  font-weight: 900;
}
</style>
